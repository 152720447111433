@import "../../assets/styles/vars.scss";

.home {
  // position: relative;
  // background-image: url("../../assets/images/5073414.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // img {
  //   height: 100vh;
  //   opacity: 0.9;
  // }

  .overlay {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container {
    margin-bottom: 50px;
    text-align: center;
    width: 90vw;
    border-radius: 10px;
  }
}
