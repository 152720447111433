@import "../../assets/styles/vars.scss";

.navbar {
  margin-top: 20px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .list {
    display: flex;
  }
  img {
    width: 100px;
    height: auto;
  }

  .menu-bar {
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    background-color: $logo-transparent-color;
    // background-color: rgba(161, 161, 161, 0.4);
    backdrop-filter: blur(10px);
    align-items: center;
    padding: 0 15px;
    li {
      list-style: none;
      color: $logo-color;
      font-family: sans-serif;
      font-weight: bold;
      padding: 12px 16px;
      margin: 0 8px;
      position: relative;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transition: 0.2s;
        border-radius: 25px;
      }

      &:hover {
        &::before {
          // background: linear-gradient(to bottom, #e8edec, #d2d1d3);
          box-shadow: 0px 1px 5px 0px $logo-color;
          // box-shadow: 0px 3px 20px 0px $logo-color;
          transform: scale(1.2);
        }
        color: $logo-color;
      }
    }
  }
}
@media screen and (max-width: $tablet-bp) {
  .navbar {
    position: relative;

    .list {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .menu-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
      li {
        padding: 6px 8px;
        margin: 4px auto;
      }
    }
  }
}
