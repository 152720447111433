@import "../../assets/styles/vars.scss";
.projects {
  min-height: 100vh;
  .grid {
    margin: 40px;
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: $laptop-bp) {
  .projects {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: $tablet-bp) {
  .projects {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
