@import "./vars.scss";
@import "./utils/button.scss";
@import "./utils/input.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap");

* {
  box-sizing: border-box;
  .en {
    font-family: "Open Sans", "TT Hoves Pro Trial", "Roboto", "Helvetica Neue",
      Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji";
  }
  .ar {
    font-family: "Noto Sans Arabic", sans-serif;
  }
}
html {
  scroll-behavior: smooth;
}
html::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
}
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #86898d;
}
::selection {
  color: #eee;
  background: #86898d;
}
html,
body {
  background-color: $ghost-white;
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  .en {
    direction: ltr !important;
  }
  .ar {
    direction: rtl !important;
  }
}
.lang {
  transition: 0.8s all ease;
}
a {
  text-decoration: none;
  color: inherit;
}
.body-container {
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
}
.p-image-preview-indicator {
  border-radius: 10px;
}
.p-image-toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #11111176;
  .p-image-action.p-link {
    color: white;
  }
}
.transparent {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  // border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: $logo-font;
}
