@import "../../../assets/styles/vars.scss";
.values {
  position: relative;
  padding: 20px;
  .header {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 35px;
      font-weight: bold;
      color: $logo-color;
    }
    .desc {
      color: $logo-font;
      font-size: 20px;
      padding-top: 20px;
    }
  }
  .grid {
    margin: 40px;
    .grid-container {
      margin: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $logo-transparent-color;
    border-radius: 20px;
    align-items: center;
    .icon {
      img {
        width: 50%;
        height: auto;
        object-fit: contain;
        object-position: center;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: $logo-font;
    }
    .desc {
      padding: 20px;
      color: $logo-font;
    }
  }
}

@media screen and (max-width: $laptop-bp) {
  .values {
    .header {
      .title {
        font-size: 25px;
      }
      .desc {
        font-size: 15px;
      }
    }
    .grid {
      .grid-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: $tablet-bp) {
  .values {
    padding: 10px;
    .header {
      .title {
        font-size: 25px;
      }
      .desc {
        font-size: 15px;
      }
    }
    .grid {
      margin: 10px;
      .grid-container {
        margin: 10px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }
    }
  }
}
