@import "../../assets/styles/vars.scss";
.service-request {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 50px 0;
  .request-form {
    width: 80%;
    background-color: $white;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    h2 {
      text-align: center;
      color: $logo-color;
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
    .service-label {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 90px;
        height: 50px;
        object-fit: cover;
      }
    }
    label {
      display: block;
      margin: 10px 5px;
      font-weight: bold;
    }
    .form-group {
      margin-bottom: 5px;
      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 25vh;
      }
    }
  }
}
