@import "../../assets/styles/vars.scss";
.service-card {
  transition: 0.8s all ease;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: auto;
  overflow: hidden;
  .title {
    padding-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: $logo-color;
    margin-bottom: 10px;
  }
  img {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
  .desc {
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $logo-color;
    transition: width 0.3s ease; /* Add a smooth transition effect */
  }

  &:hover::before {
    width: 1.5%;
  }
  &:hover {
    cursor: pointer;
  }
}
