@import "../../assets/styles/vars.scss";
.footer-distributed {
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: $white;
  color: $logo-font;
  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    h3 {
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      display: flex;
      margin: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      letter-spacing: 0.2rem;
      font-size: 2rem;
      color: $logo-color;
      img {
        width: 200px;
      }
    }
    .footer-links {
      display: flex;
      justify-content: center;
      flex-direction: row;
      .social-icon {
        padding: 10px;
        font-size: 24px;
        color: $logo-font;
        transition: color 0.3s ease;
        color: $logo-color;
        img {
          width: 50px;
          height: auto;
          object-fit: cover;
          object-position: center;
        }
        transition: 0.2s ease-in-out;
        &:hover {
          transition: 0.2s ease-in-out;
          transform: scale(1.2);
        }
      }
    }
    .footer-text {
      text-align: center;
      font-size: 1rem;
    }
  }
  .footer-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    h3 {
      padding-bottom: 20px;
      text-align: center;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .submit {
      display: flex;
      justify-content: flex-end;
      // button {
      //   border-radius: 3px;
      //   // background-color: $logo-color;
      //   // color: $logo-font;
      //   border: 0;
      //   padding: 15px 50px;
      //   font-weight: bold;
      //   transition: 0.3s ease-in-out;

      //   &:hover {
      //     transition: 0.3s ease-in-out;
      //     cursor: pointer;
      //     background-color: darken($color: $logo-color, $amount: 10);
      //     color: $logo-font;
      //   }
      // }
    }
  }
}

@media screen and (max-width: $tablet-bp) {
  .footer-distributed {
    flex-direction: column;
    .footer-left {
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }
  }
}
@media screen and (max-width: $mobile-bp) {
  .footer-distributed {
    .footer-left {
      h3 {
        gap: 5px;
        font-size: 1.4rem;
        img {
          width: auto;
          height: 80px;
        }
      }
      .footer-links {
        .social-icon {
          img {
            width: 40px;
          }
        }
      }
      .footer-text {
        font-size: 0.8rem;
      }
    }
  }
}
