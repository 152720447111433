.p-button {
  box-shadow: unset;
  transition: 0.1s ease-in-out;
  .p-icon {
    margin-left: 4px;
  }
}
.ar {
  .p-button {
    direction: ltr;
  }
}
.en {
  .p-button {
    direction: rtl;
  }
}
.p-button.info {
  border-color: unset;
  background-color: #17a2b8;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #17a2b8, $amount: 10);
  }
  &:focus {
    outline: 2px solid #17a2b8; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.warning {
  border-color: unset;
  background-color: $warning-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $warning-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $warning-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.danger {
  border-color: unset;
  background-color: #d9534f;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #d9534f, $amount: 10);
  }
  &:focus {
    outline: 2px solid #dc3545; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.primary {
  border-color: unset;
  background-color: #007bff;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #007bff, $amount: 10);
  }
  &:focus {
    outline: 2px solid #007bff; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.dark {
  border-color: unset;
  color: white;
  background-color: #6c757d;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: #6c757d, $amount: 10);
  }
  &:focus {
    outline: 2px solid #6c757d; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.logo {
  border-color: unset;
  color: white;
  background-color: $logo-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $logo-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $logo-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}
