@import "../../assets/styles/vars.scss";
.project-card {
  transition: 0.8s all ease;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    object-fit: contain;
    height: 200px;
    width: 100%;
  }

  .content {
    flex: 1; /* Let the content area fill the remaining space */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    .title {
      font-size: 24px;
      padding-top: 20px;
      font-weight: bold;
      color: $logo-color;
      margin-bottom: 10px;
    }
    .desc {
      p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $logo-color;
    transition: width 0.3s ease; /* Add a smooth transition effect */
  }

  &:hover::before {
    width: 1.5%;
  }
  &:hover {
    cursor: pointer;
  }
}
