@import "../../../assets/styles/vars.scss";
.banner {
  margin-top: 20px;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .banner-container {
    text-align: center;
    h2 {
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      font-weight: 900;
      letter-spacing: 1rem;
      margin: 2rem;
      font-size: 2.5rem;
      color: $logo-color;
    }
    .bio {
      text-align: center;
      width: 90vw;
      .desc {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.3rem;
        width: 90vw;
      }
    }
  }
}
@media screen and (max-width: $tablet-bp) {
  .banner {
    .banner-container {
      h2 {
        letter-spacing: 0.5rem;
        margin: 1rem;
        font-size: 2rem;
      }
      .bio {
        .desc {
          padding: 10px;
          font-size: 1rem;
          width: auto;
        }
      }
    }
  }
}
