@import "../../assets/styles/vars.scss";
.slider {
  direction: ltr;
  padding: 0 20px;
  // border: solid rgba(0, 0, 0, 0.4);
  // background: rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(10px);
  // box-shadow: rgba(194, 194, 194, 0.25) 0px 54px 55px,
  //   rgba(192, 192, 192, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  //   rgba(170, 167, 167, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.slider .p-carousel .p-carousel-items-content .p-carousel-items-container {
  // margin-top: 74.8px;
  min-height: 50vh;
  width: 100%;
}

.slider
  .p-carousel
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item {
  display: flex;
  justify-content: center;
}

.slider
  .p-carousel
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item
  .image-container {
  height: 70vh;
  width: 100%;
  text-align: center;
  position: relative;
}

.slider
  .p-carousel
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item
  .image-container
  img {
  width: 100%;
  height: 100%; /* Set image height to fill the container */
  border-radius: 10px;
  object-fit: cover;
}

.slider
  .p-carousel
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item
  .image-container
  .middle-paragraph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $white;
  background-color: rgba(0, 0, 0, 0.655);
  padding: 20px;
  border-radius: 10px;
}

.slider
  .p-carousel
  .p-carousel-items-content
  .p-carousel-items-container
  .p-carousel-item
  .image-container
  .middle-paragraph
  .header {
  font-size: 25px;
  font-weight: bold;
}
@media screen and (max-width: $mobile-bp) {
  .slider
    .p-carousel
    .p-carousel-items-content
    .p-carousel-items-container
    .p-carousel-item
    .image-container {
    height: 80vh;
  }
  .slider
    .p-carousel
    .p-carousel-items-content
    .p-carousel-items-container
    .p-carousel-item
    .image-container
    .middle-paragraph
    .header {
    font-size: 15px;
    font-weight: bold;
  }
}
