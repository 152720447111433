// $dark-font-color: #6a6a6a;
// $light-font-color: #343a40;

// $primary-color: #3d86f3;
// $logo-color: #6c757d;
// $success-color: #198754;
$warning-color: #ffc107;

$white: #ffffff;
$white-smoke: #f5f5f5;
$ghost-white: #f8f8ff;
$logo-color: #225f8f;
$logo-font: #1d1d1b;
$logo-transparent-color: #22608f1b;

$mobile-bp: 480px;
$tablet-bp: 768px;
$laptop-bp: 960px;
$screen-bp: 1200px;

$shadow-style: 0 2px 4px rgba(19, 19, 19, 0.1);
